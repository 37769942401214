
import CarsDocumentItemModel from '@/modules/cars/models/cars-document-item.model';
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import PercentFilter from '@/modules/common/filters/percent.filter';
import type Day from '@/modules/common/types/day.type';
import Percent from '@/modules/common/types/percent.type';
import MIPriceFilter from '@/modules/common/filters/mi-price.filter';
import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import DayChanger from '@/modules/common/components/day-changer.vue';
import Currency from '@/modules/common/components/currency.vue';
import PopupEventsContainer from '@/modules/events/components/popup-events-container.vue';
import CompsetScale from '@/modules/common/components/compset-scale.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import Occupancy from '@/modules/common/components/ui-kit/occupancy.vue';
import MedianComparisonTooltip from '@/modules/cars/components/median-comparison-tooltip.vue';
import CarFleetAvailabilityTooltip from '@/modules/cars/components/car-fleet-availability-tooltip.vue';
import CarTogglePrices from '@/modules/cars/components/car-toggle-prices.vue';
import CarPriceTable from '@/modules/cars/components/car-price-table.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import CarsFiltersService, { CarsFiltersServiceS } from '../cars-filters.service';
import CarsSharedService, { CarsSharedServiceS } from '../cars-shared.service';

export interface TableData {
    price: number | null,
    company: string | null,
    carBrand: string | null,
    isAvailable?: boolean,
    screenshot: string | null,
    isMyCar: boolean,
    isMedian: boolean,
    sippCode?: string | null,
    shopDate?: Date | null,
    rectangleUniqueness?: string | null,
    rank?: number | null,
    originCompany?: string | null,
}

@Component({
    components: {
        DayChanger,
        ModalWrapper,
        Currency,
        PopupEventsContainer,
        CompsetScale,
        LoaderWrapper,
        Occupancy,
        CarFleetAvailabilityTooltip,
        MedianComparisonTooltip,
        CarPriceTable,
        CarTogglePrices,
    },
    filters: { PercentFilter, MIPriceFilter },
})
export default class CarPopup extends Vue {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    medianComparisonTooltip: boolean = false;

    get title() {
        this.carsSharedService.updateFiltersFromSessionStorageIfExist();
        return this.carsFiltersService.selectedLocationName;
    }

    get documents() {
        return !!this.carsService.storeState.document;
    }

    get compsetDataType() {
        if (this.carsService) {
            return {
                isNA: this.isNA,
                isSoldOut: this.isSoldOut,
                isNoData: false,
            };
        }
        return {};
    }

    get priceType() {
        return this.carsService.storeState.settings.priceType;
    }

    get occupancyTitle() {
        if (!this.occupancyLevel) {
            return 'N/A';
        }
        return `${this.countCars} out of ${this.totalCars} (${this.occupancyLevel}%)`;
    }

    get occupancyLevel() {
        return this.carsService.occupancy(this.day) || 0;
    }

    get countCars() {
        const { countCars } = this.carsService.fleetAvailability(this.day);
        return countCars;
    }

    get totalCars() {
        const { totalCars } = this.carsService.fleetAvailability(this.day);
        return totalCars;
    }

    get day(): Day {
        return parseInt(this.$route.params.day, 10) as Day;
    }

    get id(): string | null {
        const currentCar = this.carsService.currentCar(this.day) as CarsDocumentItemModel;
        if (currentCar) {
            return currentCar.identifier;
        }

        const competitorCars = this.carsService.competitorCars(this.day) as {
            [company: string]: CarsDocumentItemModel
        };
        if (competitorCars) {
            const competitorCarsKey = Object.keys(competitorCars);

            if (competitorCarsKey.length) {
                return competitorCars[competitorCarsKey[0]].identifier;
            }
        }

        return null;
    }

    get isNA() {
        return this.carsService.allCars(this.day) === false;
    }

    get isSoldOut() {
        return this.carsService.currentCar(this.day) === null;
    }

    get cardColorClass() {
        if (!this.percent) {
            return { green: this.percent === 0 };
        }

        const assessment = this.carsService.getCardAssessment(this.percent);

        return {
            green: assessment === ASSESSMENTS_TYPES.GOOD,
            yellow: assessment === ASSESSMENTS_TYPES.NORMAL,
            red: assessment === ASSESSMENTS_TYPES.BAD || this.isCompetitorsSoldOut(this.day),
        };
    }

    isCompetitorsSoldOut(day: Day) {
        return this.carsService.isCompetitorsSoldOut(day);
    }

    get percent(): Percent | null {
        return this.carsService.competitorPercent(this.day);
    }

    get shopDate() {
        const date = this.carsService.calculateShopDate(this.day);

        if (!date) {
            return null;
        }

        const day = (`0${date.getUTCDate()}`).slice(-2);
        const month = (`0${date.getUTCMonth() + 1}`).slice(-2);
        const year = date.getUTCFullYear();
        const hours = (`0${date.getUTCHours()}`).slice(-2);
        const minutes = (`0${date.getUTCMinutes()}`).slice(-2);

        return `${day}/${month}/${year} ${hours}:${minutes} UTC`;
    }

    get isOutOfRange() {
        const cars = this.carsService.allCars(this.day);
        return cars === null;
    }

    get showFleetIndicator() {
        const { branches } = this.carsFiltersService;
        if (!branches) {
            return true;
        }
        const { chain } = this.carsService.storeState.settings;

        return !(chain && chain.chainId);
    }

    get isAvgPrice() {
        return this.carsService.storeState.settings.isAvgPrice;
    }

    async openPriceHistory() {
        await this.$router.push({
            name: `${this.$route.name}.price-history-popup`,
            params: { historyDay: `${this.day}` },
        });
    }

    get isAvailability() {
        return this.carsFiltersService.isAvailability;
    }
}
